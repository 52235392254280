/* Member Edit, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="member-edit" class="entity-edit">
    <v-card-title>Edit {{entity.FirstName}} {{entity.LastName}}</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-alert
          v-if="serverErrorMessage"
          type="error"
        >
          {{ serverErrorMessage }}
        </v-alert>
      </v-card-text>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>
        <v-btn
          color="primary"
          class="me-3"
          @click="Save"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiContentSaveOutline  }}
          </v-icon>
          <span>Save</span>
        </v-btn>
        </v-col>
        <v-col class="text-right">
        <v-btn
          color="secondary"
          class="me-3"
          @click="Back"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiChevronLeft }}
          </v-icon>
          <span>Back</span>
        </v-btn>
        </v-col>
      </v-row>
      </v-card-text>

      <v-form ref="form">
      <v-simple-table dense>
        <thead>
          <tr>
            <th colspan="2">MEMBER DETAILS</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Reference</td>
            <td>
              <v-text-field label="Reference"
                            solo-inverted
                            flat
                            :error-messages="serverErrors.filter(x => x.field === 'Reference').map(x => x.error)"
                            v-model="entity.Reference"
                            :rules="[validators.required]"></v-text-field>
            </td>
          </tr>

          <tr>
            <td>Email</td>
            <td>
              <v-text-field label="Email"
                            solo-inverted
                            flat
                            :error-messages="serverErrors.filter(x => x.field === 'Email').map(x => x.error)"
                            v-model="entity.Email"
                            :rules="[validators.required]"></v-text-field>
            </td>
          </tr>

          <tr>
            <td>FirstName</td>
            <td>
              <v-text-field label="First Name"
                            solo-inverted
                            flat
                            :error-messages="serverErrors.filter(x => x.field === 'FirstName').map(x => x.error)"
                            v-model="entity.FirstName"
                            :rules="[validators.required]"></v-text-field>
            </td>
          </tr>

          <tr>
            <td>LastName</td>
            <td>
              <v-text-field label="Last Name"
                            solo-inverted
                            flat
                            :error-messages="serverErrors.filter(x => x.field === 'LastName').map(x => x.error)"
                            v-model="entity.LastName"
                            :rules="[validators.required]"></v-text-field>
            </td>
          </tr>

          <tr>
                  <td>Salutation</td>
                  <td><v-text-field
                label="Salutation"
                solo-inverted
                flat
                :error-messages="serverErrors.filter(x => x.field === 'Salutation').map(x => x.error)"
                v-model="entity.Salutation"
                :rules="[]"
              ></v-text-field>
          </td>
                </tr>



          <tr>
            <td>Country</td>
            <td><CountryLookup v-model="entity.CountryId" :rules="[validators.requiredInteger]" :error-messages="serverErrors.filter(x => x.field === 'CountryId').map(x => x.error)" /></td>
          </tr>

          <tr>
            <td>DOB</td>
            <td><DateField v-model="entity.DOB" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'DOB').map(x => x.error)" /></td>
          </tr>

          <tr>
            <td>Telephone</td>
            <td>
              <v-text-field label="Telephone"
                            solo-inverted
                            flat
                            :error-messages="serverErrors.filter(x => x.field === 'Telephone').map(x => x.error)"
                            v-model="entity.Telephone"
                            :rules="[]"></v-text-field>
            </td>
          </tr>

          <tr>
            <td>Partner Marketing</td>
            <td><v-checkbox v-model="entity.PartnerMarketing" :color="(entity.PartnerMarketing ? 'success' : 'secondary')"></v-checkbox></td>
          </tr>
          <tr>
            <td>Enabled</td>
            <td><v-checkbox v-model="entity.Enabled" :color="(entity.Enabled ? 'success' : 'secondary')"></v-checkbox></td>
          </tr>

          <!--<tr>
          <td>Role</td>
          <td><v-text-field
        label="Role"
        solo-inverted
        flat
        :error-messages="serverErrors.filter(x => x.field === 'Role').map(x => x.error)"
        v-model="entity.Role"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>-->


        </tbody>
      </v-simple-table>
      </v-form>

  </v-card>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
import * as FrameworkValidators from '@core/utils/validation'
import * as Validators from '@/datahelpers/validation'
import DateField from '@/views/helpers/DateField'
import CountryLookup from '@/views/country/CountryLookup'

import {
  mdiContentSaveOutline,
  mdiChevronLeft
} from '@mdi/js'
import { ref, watch, computed, expose } from '@vue/composition-api'

export default {
  components: {
    DateField,
    CountryLookup
  },
  setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => {
      return store.state.app.Member;
    });
    const loading = ref(false);
    const form = ref(null);
    const serverErrors = ref([]);
    const serverErrorMessage = ref(null);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchMember', {
          id: id
        })
        .then(response => {
          loading.value = false;
        })
        .catch(error => {
          loading.value = false;
          console.log(error);
          router.push({ name: 'member-list', params: { id: route.value.params.id } });
        })
    }

    // fetch the user information when params change
    watch(() => route.value.params.id, (id) => fetchData(id));

    // and call now
    fetchData(route.value.params.id);


    const Save = function(e) {

      serverErrorMessage.value = null;
      serverErrors.value = [];

      const valid = form.value.validate();

      if (!valid) {
        return;
      }

      loading.value = true;

      store
        .dispatch('app/updateMember', {
          id: route.value.params.id,
          entity: entity.value
        })
        .then(response => {
          loading.value = false;
          router.push({ name: 'member-view', params: { id: response.data.Id } });
        })
        .catch((error) => {
          loading.value = false;
          if (error.error) {
            if (error.message) serverErrorMessage.value = error.message;
            if (error.errors) serverErrors.value = error.errors
          } else {
            console.log(error);
          }
        });
    };

    const Back = () => {
      router.go(-1);
    };

    const validators = {
      ...FrameworkValidators,
      ...Validators
    };

    return {
      Back,
      form,
      Save,
      loading,
      entity,
      Display,
      icons: {
        mdiContentSaveOutline ,
        mdiChevronLeft
      },
      validators,
      serverErrorMessage,
      serverErrors
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-edit::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
  padding-right: 0;
}
.entity-edit::v-deep .v-data-table th:last-child {
  width: 66%;
}
.entity-edit::v-deep  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}
.entity-edit::v-deep .v-input:not(.error--text) .v-messages {
  min-height: unset;
}
.entity-edit::v-deep .v-input .v-messages.error--text {
  margin-bottom: 8px;
}
.entity-edit::v-deep .v-input:not(.error--text) .v-text-field__details {
  min-height: unset;
  margin-bottom: 0;
}
.entity-edit::v-deep .v-input:not(.error--text) .v-input__slot {
  margin: 0;
  border-radius: 0;
}
.entity-edit::v-deep .v-input--selection-controls {
  margin: 10px;
}
</style>

